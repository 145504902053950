
import { defineAsyncComponent, ref } from 'vue'
import { Tag, createTag } from 'momai'
import { saveOrUpdate, del } from '@/api/entity/tag'
import { Dialog, Notify } from 'vant'
import { goBack } from '@/states/layout'
import { allTags, fetchTags } from '@/states/tags'
import { useRoute } from 'vue-router'

export default {
  components: {
    TagForm: defineAsyncComponent(() => import('../Form.vue'))
  },
  setup: () => {
    const { tagId } = useRoute().params
    const targetTag = allTags.value.find(t => t.id === Number(tagId))
    const tag = ref<Tag>(createTag(targetTag))
    return {
      tag,
      doSubmit: (canSubmit: () => boolean) => {
        if (!canSubmit()) {
          return false
        }
        saveOrUpdate(tag.value).then(() => {
          fetchTags()
          Notify({
            type: 'success',
            message: '标签编辑成功！'
          })
          goBack()
        })
      },
      askDel: () => {
        Dialog.confirm({
          title: '确认',
          message: '确认删除吗？'
        }).then(() => {
          del(Number(tagId)).then(() => {
            fetchTags()
            Notify({
              type: 'success',
              message: '标签删除成功！'
            })
            goBack()
          })
        }).catch(() => {})
      }
    }
  }
}
